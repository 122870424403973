import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  Phone as PhoneIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import axios from 'axios';
import MetricCard from './MetricCard';
import LiveCallPanel from './LiveCallPanel';
import Sidebar from './Sidebar';

const Dashboard = () => {
  const theme = useTheme();
  const [stats, setStats] = useState({
    totalCalls: 0,
    totalEnrollments: 0,
    closingPercentage: 0,
    trends: {
      calls: [],
      enrollments: [],
      closing: [],
    },
  });
  const [timeframe, setTimeframe] = useState('day');
  const [currentCall, setCurrentCall] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStats = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/api/stats?timeframe=' + timeframe);
      setStats(response.data);
      setIsEnabled(response.data.enabled);
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTargetStatus = async (enabled) => {
    try {
      await axios.post('/api/target/status', { enabled });
      setIsEnabled(enabled);
      fetchStats();
    } catch (error) {
      console.error('Error updating target status:', error);
    }
  };

  // Poll for current call
  useEffect(() => {
    const pollCurrentCall = async () => {
      try {
        const response = await axios.get('/api/current-call');
        if (response.data) {
          setCurrentCall(response.data);
        }
      } catch (error) {
        console.error('Error fetching current call:', error);
      }
    };

    const interval = setInterval(pollCurrentCall, 5000);
    return () => clearInterval(interval);
  }, []);

  // Poll for stats
  useEffect(() => {
    fetchStats();
    const interval = setInterval(fetchStats, 30000);
    return () => clearInterval(interval);
  }, [timeframe]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
      <Sidebar />
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: { sm: '240px' },
        }}
      >
        <Container maxWidth="xl">
          {currentCall && (
            <Box sx={{ mb: 4 }}>
              <LiveCallPanel currentCall={currentCall} />
            </Box>
          )}

          <Box
            sx={{
              mb: 4,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" component="h1">
              Dashboard Overview
            </Typography>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Select
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
                size="small"
                sx={{
                  minWidth: 120,
                  bgcolor: 'background.paper',
                }}
              >
                <MenuItem value="day">Today</MenuItem>
                <MenuItem value="month">This Month</MenuItem>
                <MenuItem value="year">This Year</MenuItem>
              </Select>

              <IconButton
                onClick={fetchStats}
                sx={{ bgcolor: 'background.paper' }}
                disabled={isLoading}
              >
                <RefreshIcon />
              </IconButton>

              <Button
                variant="contained"
                color={isEnabled ? "error" : "success"}
                startIcon={isEnabled ? <PauseIcon /> : <PlayArrowIcon />}
                onClick={() => handleTargetStatus(!isEnabled)}
                sx={{ minWidth: 140 }}
              >
                {isEnabled ? 'Pause Target' : 'Start Target'}
              </Button>
            </Box>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MetricCard
                title="Total Calls"
                value={stats.totalCalls}
                trend={stats.trends?.calls?.trend}
                trendData={stats.trends?.calls}
                icon={PhoneIcon}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MetricCard
                title="Total Enrollments"
                value={stats.totalEnrollments}
                trend={stats.trends?.enrollments?.trend}
                trendData={stats.trends?.enrollments}
                icon={GroupIcon}
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MetricCard
                title="Closing %"
                value={stats.closingPercentage}
                trend={stats.trends?.closing?.trend}
                trendData={stats.trends?.closing}
                icon={TrendingUpIcon}
                color="warning"
                isPercentage
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
