import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Avatar,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
  Notes as NotesIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(46, 125, 50, 0.95) 0%, rgba(27, 94, 32, 0.95) 100%)',
  backdropFilter: 'blur(10px)',
  borderRadius: 16,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  overflow: 'visible',
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: 16,
  '& .MuiChip-label': {
    color: theme.palette.common.white,
  },
  '& .MuiChip-icon': {
    color: theme.palette.common.white,
  },
}));

const LiveCallPanel = ({ currentCall }) => {
  if (!currentCall) return null;

  return (
    <AnimatePresence>
      <StyledCard
        component={motion.div}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ type: 'spring', stiffness: 100 }}
        elevation={4}
      >
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <Box
              component={motion.div}
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ repeat: Infinity, duration: 2 }}
              sx={{
                backgroundColor: 'error.main',
                borderRadius: '50%',
                width: 12,
                height: 12,
                mr: 2,
              }}
            />
            <Typography variant="h5" color="common.white">
              Live Call Incoming
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                  sx={{
                    width: 48,
                    height: 48,
                    bgcolor: 'primary.main',
                    mr: 2,
                  }}
                >
                  {currentCall.name[0]}
                </Avatar>
                <Typography variant="h6" color="common.white">
                  {currentCall.name}
                </Typography>
              </Box>

              <Box display="flex" flexWrap="wrap" gap={1}>
                <InfoChip
                  icon={<PhoneIcon />}
                  label={currentCall.phone}
                  variant="outlined"
                />
                <InfoChip
                  icon={<EmailIcon />}
                  label={currentCall.email}
                  variant="outlined"
                />
                <InfoChip
                  icon={<LocationIcon />}
                  label={currentCall.location}
                  variant="outlined"
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="common.white"
                  sx={{ opacity: 0.7, mb: 1 }}
                >
                  Notes
                </Typography>
                <Typography color="common.white">
                  {currentCall.notes}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </AnimatePresence>
  );
};

export default LiveCallPanel;
