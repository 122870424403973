import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Collapse,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import CountUp from 'react-countup';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler
);

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const ExpandButton = styled(IconButton)(({ theme, expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s',
  marginLeft: 'auto',
}));

const MetricCard = ({
  title,
  value,
  trend,
  trendData,
  isPercentage,
  color = 'primary',
  icon: Icon,
}) => {
  const [expanded, setExpanded] = useState(false);

  const chartData = {
    labels: trendData?.labels || [],
    datasets: [
      {
        label: title,
        data: trendData?.values || [],
        fill: true,
        borderColor: `rgba(227, 24, 55, 1)`,
        backgroundColor: `rgba(227, 24, 55, 0.1)`,
        tension: 0.4,
        pointRadius: 0,
        pointHitRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <StyledCard
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          {Icon && (
            <Box
              component={motion.div}
              whileHover={{ rotate: 15 }}
              sx={{
                backgroundColor: `${color}.main`,
                borderRadius: '50%',
                p: 1,
                mr: 2,
              }}
            >
              <Icon />
            </Box>
          )}
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
          <ExpandButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            size="small"
          >
            <ExpandMoreIcon />
          </ExpandButton>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" component="div">
            <CountUp
              end={value}
              duration={2}
              separator=","
              suffix={isPercentage ? '%' : ''}
              decimals={isPercentage ? 1 : 0}
            />
          </Typography>
          {trend && (
            <Tooltip title="7-day trend">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: trend >= 0 ? 'success.main' : 'error.main',
                }}
              >
                <Typography variant="body2">
                  {trend > 0 ? '+' : ''}
                  {trend}%
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Box>

        <Collapse in={expanded}>
          <Box
            sx={{
              height: 200,
              mt: 2,
            }}
          >
            <Line data={chartData} options={chartOptions} />
          </Box>
        </Collapse>
      </CardContent>
    </StyledCard>
  );
};

export default MetricCard;
